<!-- eslint-disable vue/no-parsing-error -->
<template>
  <div class="bg-white flex flex-col justify-center items-stretch">
    <div
      class="flex-col box-border overflow-hidden relative flex h-[100vh] w-full justify-start pr-0 pr-md-16 items-start max-md:max-w-full max-md:pr-0"
    >
      <img
        src="@/assets/images/landing-broker.jpeg"
        class="absolute h-full w-full object-cover object-center inset-0"
      />
      <div
        class="backdrop-blur-[21px] box-border bg-black bg-opacity-70 flex w-full h-[100vh] overflow-auto hide-scrollbar max-w-full flex-col justify-center items-center px-16 max-md:px-5"
      >
        <!-- <div class="flex w-full h-full justify-center items-center">
          <div class="loader-square square reg positioning">
            <div class="block">
              <div class="box"></div>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { useCookies } from "vue3-cookies";
import { login } from "@/services/axios/login.service.js";
import { floors } from "@/services/axios/floors.service.js";
import { useRouter } from "vue-router";
import { useStore } from "@/store/loading.js";
import { useFloorplan } from "@/store/floor.js";

const store = useStore();
const router = useRouter();
const { cookies } = useCookies();

const force = ref(false);
onMounted(() => {
  console.log('onMounted login');
  
  // if (process.env.VUE_APP_MANDATORY_LOGIN) force.value = true;
  router.push({ name: "floorplans", query: { full_3d: true } });
  // sendMail()
});

const duplicateUserCheck = () => {
  localStorage.clear();
};

const sendMail = async () => {
  try {
    const valid = true;
    if (valid) {
      const mail = {
        email: 'default-user@default.com',
      };
      store.isLoading = true;
      const { data } = await login(mail);
      if (data.token) {
        const email = localStorage.getItem("email");
        if (email) duplicateUserCheck();
        localStorage.setItem("email", mail.email);
        localStorage.setItem("user_type", "Customer"); // Customer or Broker
        localStorage.setItem("status", data.status);
        localStorage.setItem("organization_id", data.organization);
        localStorage.setItem("project_id", data.project);
        cookies.set("token", data.token, '6m', '6m');
        store.accessToken = data.token;
        store.isAgent = false;
        const res = await floors();
        const floorplan = useFloorplan();
        const compare = (a, b) => {
          return a.area - b.area;
        };
        res.data.sort(compare);
        floorplan.addFloorplan(res.data);
        router.push({ name: "floorplans", query: { full_3d: true } });
      }
    } else {
      console.error("error");
    }
  } catch (error) {
    console.error(error);
  }
};
</script>

<style lang="scss" scoped>
$width: 10rem;
$color: #f47e3a;
.positioning {
  margin: 50px auto;
  position: relative;
}

.loading-sticks {
  height: $width;
  width: $width;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .loading {
    flex: 0 0 20%;
    height: 30%;
    animation: pulse 1s ease infinite;
    background-color: $color;
    animation-delay: 0;
    &:nth-child(2) {
      animation-delay: 0.3s;
    }
    &:nth-child(3) {
      animation-delay: 0.6s;
    }
  }
}

@keyframes pulse {
  0% {
    height: 30%;
  }
  50% {
    height: 100%;
  }
  100% {
    height: 30%;
  }
}

.loader-spinner {
  width: 72px;
  height: 72px;
  margin: 50px auto;
  div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    &:before {
      display: block;
      width: 15%;
      height: 15%;
      margin: 0 auto;
      content: "";
      border-radius: 100%;
      background-color: $color;
      transform: translate(-50%, -50%);
      animation: spinner 1.2s infinite ease-in-out both;
    }
    &:nth-child(2) {
      transform: rotate(30deg);
      &:before {
        animation-delay: -1.1s;
      }
    }
    &:nth-child(3) {
      transform: rotate(60deg);
      &:before {
        animation-delay: -1s;
      }
    }
    &:nth-child(4) {
      transform: rotate(90deg);
      &:before {
        animation-delay: -0.9s;
      }
    }
    &:nth-child(5) {
      transform: rotate(120deg);
      &:before {
        animation-delay: -0.8s;
      }
    }
    &:nth-child(6) {
      transform: rotate(150deg);
      &:before {
        animation-delay: -0.7s;
      }
    }
    &:nth-child(7) {
      transform: rotate(180deg);
      &:before {
        animation-delay: -0.6s;
      }
    }
    &:nth-child(8) {
      transform: rotate(210deg);
      &:before {
        animation-delay: -0.5s;
      }
    }
    &:nth-child(9) {
      transform: rotate(240deg);
      &:before {
        animation-delay: -0.4s;
      }
    }
    &:nth-child(10) {
      transform: rotate(270deg);
      &:before {
        animation-delay: -0.3s;
      }
    }
    &:nth-child(11) {
      transform: rotate(300deg);
      &:before {
        animation-delay: -0.2s;
      }
    }
    &:nth-child(12) {
      transform: rotate(330deg);
      &:before {
        animation-delay: -0.1s;
      }
    }
  }
}

@keyframes spinner {
  0% {
    transform: scale(0, 0);
  }
  10% {
    transform: scale(0, 0);
  }
  50% {
    transform: scale(1, 1);
  }
  90% {
    transform: scale(0, 0);
  }
  100% {
    transform: scale(0, 0);
  }
}

//Loader from https://site.uplabs.com/posts/box-loaders
$s: 32px; //size
$m: 2px; //margin
$d: 1s; //speed
//colors
$bg: red; //#eceff1;
.loader-square.positioning {
  position: absolute;
  top: 300px;
  left: 50%;
}

.loader-square {
  z-index: 10000;
  box-sizing: border-box;
  &.square {
    .block {
      border-radius: $m * 2;
      width: 2 * $s + $m * 5;
      height: 2 * $s + $m * 5;
      box-sizing: border-box;
      &:before,
      &:after {
        bottom: $m;
      }
      &:before {
        left: $m;
      }
      &:after {
        right: $m;
      }
      .box {
        left: $m;
        top: $m;
        animation-name: slide;
      }
    }
  }
  &.reg {
    .block {
      animation-name: reg;
    }
  }
  .block {
    position: absolute;
    color: $color;
    border: $m solid;
    transform: translate(-50%, -50%) rotate(-45deg);
    animation-timing-function: ease-in-out;
    animation-duration: $d;
    animation-iteration-count: infinite;
    &:before,
    &:after {
      content: "";
      position: absolute;
      width: $s;
      height: $s;
      background: $color;
      border-radius: $m * 2;
    }
    &:before {
      left: $m;
    }
    &:after {
      right: $m;
    }
  }
  .box {
    position: absolute;
    width: $s;
    height: $s;
    background: $color;
    border-radius: $m * 2;
    animation-timing-function: ease-in-out;
    animation-duration: $d;
    animation-iteration-count: infinite;
  }
}

@keyframes slide {
  33% {
    left: $m;
  }
  67%,
  100% {
    left: $m * 2 + $s;
  }
}

@keyframes reg {
  50% {
    transform: translate(-50%, -50%) rotate(45deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(45deg);
  }
}

//Loader from https://site.uplabs.com/posts/loading-interface-inspiration
$loader--width: 250px;
$loader-dot--size: 20px;
.loader-line.positioning {
  margin-top: 200px;
}

.loader-line {
  z-index: 10000;
  height: $loader-dot--size;
  width: $loader--width;
  .loader--dot {
    animation: loaderline ease-in-out 3s infinite;
    height: $loader-dot--size;
    width: $loader-dot--size;
    border-radius: 100%;
    background-color: black;
    position: absolute;
    background-color: $color;
    &:first-child {
      opacity: 0.3;
      animation-delay: 0.5s;
    }
    &:nth-child(2) {
      opacity: 0.4;
      animation-delay: 0.4s;
    }
    &:nth-child(3) {
      opacity: 0.5;
      animation-delay: 0.3s;
    }
    &:nth-child(4) {
      opacity: 0.6;
      animation-delay: 0.2s;
    }
    &:nth-child(5) {
      opacity: 0.8;
      animation-delay: 0.1s;
    }
    &:nth-child(6) {
      opacity: 1;
      animation-delay: 0s;
    }
  }
  .loader--text {
    position: absolute;
    top: 200%;
    left: 0;
    right: 0;
    width: 4rem;
    margin: auto;
    &:after {
      content: "Loading";
      font-weight: bold;
      font-size: 18px;
      color: black;
      animation: loading-text 3s infinite;
    }
  }
}

@keyframes loaderline {
  15% {
    transform: translateX(0);
  }
  45% {
    transform: translateX($loader--width - $loader-dot--size);
  }
  65% {
    transform: translateX($loader--width - $loader-dot--size);
  }
  95% {
    transform: translateX(0);
  }
}

@keyframes loading-text {
  0% {
    content: "Loading";
  }
  25% {
    content: "Loading.";
  }
  50% {
    content: "Loading..";
  }
  75% {
    content: "Loading...";
  }
}
</style>
